.App {
  text-align: justify;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  width: 100%;
}
.flex{
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  column-gap: 40px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.flex .card{
  
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  width: 80%;
}
.flex .card img{
  border-radius: 5px;
  width: 100%;
}
.card_link img:hover{
  
  transition: 0.3s ease-in;
  
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.title:hover .happy, .demon{
  display: none;
}
.title:hover .demon{
  display: inline-block;
  transition: .2s ease;
}
.header{
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: fixed;
  top: 0;
  align-items: center;
  z-index: 1000;
  height: 10vh;
  background-color: rgb(255, 255, 255);
}

.header ul{
  display: flex;
  justify-content: space-evenly;
  column-gap: 20px;
}
.header ul a, .title a{
  text-decoration: none;
  color: black;
}
.header ul li:first-child{
  text-decoration: none;
  color: #d047e2;
}
.header ul li:hover, .header ul li a:hover,.title a:hover{
  color:rgb(208, 71, 226);
  transition: .3s ease-in;
  cursor: pointer;
}
.header ul li{
  list-style: none;
  font-size:larger;
  font-weight: bolder;
}

.hero{
  height: 60vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  flex-direction: row;
  padding: 60px;
  box-shadow: 0 8px 6px -6px rgb(208, 71, 226);
}
.hero img{
  width: 100%;
}
.hero_content{
  width: 300px;
  height: 400px;
  font-size: 48px;
  font-weight: bold;
}
.hero_content p{
  margin: unset;
}
.hero h2, .info h2, .work_h2{
  font-size: 60px;
  font-weight: bolder;
  color: rgb(174, 20, 194);
  margin: unset;
}
.card h3, .knowledge_title{
  margin-top: 40px;
  font-size: 2rem;
  color: rgb(174, 20, 194);
  font-weight: bold;
}
footer{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2.5rem;
  padding: 15px;
}
.socials{
  font-size: 4rem;
}
.socials:hover{
  transform: scale(1.2);
  -webkit-filter: drop-shadow(5px 5px 5px rgb(213, 180, 219));
  filter: drop-shadow(5px 5px 5px rgb(216, 186, 221));
  transition: .2s ease-in;
}
/* Forms */
.about_us{
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 100px;
  position: relative;
}
.about_us_menu{
  position: fixed;
  display: flex;
  font-size: 40px;
  bottom: 5%;
  z-index: 1000;
  background-color: rgba(204, 0, 255, 0.479);
  width: 250px;
  height: fit-content;
  border-radius: 25px;
}
.about_us_menu ul{
  width: 100%;
  padding: 30px;
  margin: unset;
  padding: unset;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about_us_menu li{
  list-style: none;
  padding-top: 5px;
}
.about_us_menu li:hover{
  cursor: pointer;
}
.about_us_menu li a{
  color: white;
}
.info p, .card p{
  font-size: 20px;
  font-weight: bold;
}

.card{
  text-align: center;
}
.info_list li{
  list-style: none;
  font-weight: 600;
  font-size: 1.2rem;
}
.know_card{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background-color: rgba(208, 71, 226, 0.534);
  padding: 0.2rem;
  border-radius: 5px;
  justify-content: center;
  justify-items: center;
}
.know_card:hover{
  transform: scale(1.1);
  cursor: pointer;
  transition: .2s ease;
}
.know_card small{
  font-weight: bold;
  word-break: break-all;
  word-wrap:break-word;
}
.know_icon{
  font-size: 2rem;
}
#knowledge__{
  padding-top: 11vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.know_container{
  
  display: grid;
  width: 60%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
form{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  justify-content: space-evenly;
}

input,textarea{
  padding: 1rem;
  border-radius: 5px;
  resize: none;
  border: 2px solid rgb(208, 71, 226);
}
input[type=submit]{
  border: 2px solid transparent;
  background-color: rgb(212, 212, 224);
  color: black;
  font-weight: bold;
}
input[type=submit]:hover{
  cursor: pointer;
  border: 2px solid rgb(169, 169, 175);
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (max-width: 1191px){
  .know_container{
    grid-template-columns: 1fr 1fr;
    width: 80%;
  }
  
}
@media  screen and (max-width: 760px ) {
  .hero{
    height: fit-content;
    margin-top: 11vh;
    row-gap: 30px;
  }
  .hero_img{
    order: 2;
  }
  .hero_content{
    height: fit-content;
    font-size: 30px;
  }
  
  .socials{
    font-size: 2rem;
  }
  .info{
    width: 80%;
    text-align: start;
  }
}

@media screen and (max-width: 400px){
  h1{
    font-size: 1.3em;
  }
}